import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Helmet from 'react-helmet';

import Translate from '@accrosoft-ltd/accropress-websites-components/dist/esm/translateWidget';

import HeaderMenuBarNested from '@accrosoft-ltd/accropress-websites-components/dist/esm/HeaderMenuBarNested';

const Header = (props) => {
  const [scrolled, setScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [toggleMobileNav, setToggleMobileNav] = useState(false);

  const [websiteNavData, setWebsiteNavData] = useState([]);

  const [websiteNavLoading, setWebsiteNavLoading] = useState(true);

  const [searchExpanded, setSearchExpanded] = useState(false);

  const [translationsExpanded, setTranslationsExpanded] = useState(false);

  const accropressWebsiteNavigation = useStaticQuery(graphql`
    query {
      allAccroPress {
        totalCount
        nodes {
          menu {
            id
            slug
            parentSlug
            title
            order
            isPublished
            children {
              id
              order
              isPublished
              parentId
              slug
              parentSlug
              title
            }
          }
        }
      }
    }
  `);

  const returnSortedNavigation = async function (websiteNavJSON, fromGQL) {
    if (websiteNavLoading) {
      function GetSortOrder(prop) {
        return function (a, b) {
          if (a[prop] > b[prop]) {
            return 1;
          } else if (a[prop] < b[prop]) {
            return -1;
          }
          return 0;
        };
      }

      function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === '-') {
          sortOrder = -1;
          property = property.substr(1);
        }
        return function (a, b) {
          /* next line works with strings and numbers,
           * and you may want to customize it to your needs
           */
          var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
          return result * sortOrder;
        };
      }

      async function procesItem(item, newArrayOrder) {
        // notice that we can await a function
        // that returns a promise
        if (item.children) {
          const newChildrenOrder = await item.children.sort(
            dynamicSort('order')
          );

          item.children = newChildrenOrder;

          newArrayOrder.push(item);
        } else {
          newArrayOrder.push(item);
        }

        return item;
      }

      async function processArray(array) {
        let newArrayOrder = [];

        for (const item of array) {
          await procesItem(item, newArrayOrder);
        }

        newArrayOrder = await newArrayOrder.sort(dynamicSort('order'));

        return newArrayOrder;
      }

      if (fromGQL) {
        const websiteNavItems = await processArray(
          websiteNavJSON.allAccroPress.nodes[0].menu
        );

        if (websiteNavItems) {
          setWebsiteNavData(websiteNavItems);
        } else {
          setWebsiteNavData(websiteNavJSON.allAccroPress.nodes[0].menu);
        }

        setWebsiteNavLoading(false);
      } else {
        const websiteNavItems = await processArray(websiteNavJSON.menu);

        if (websiteNavItems) {
          setWebsiteNavData(websiteNavItems);
        } else {
          setWebsiteNavData(websiteNavJSON.menu);
        }

        setWebsiteNavLoading(false);
      }
    }
  };

  const searchOnClick = function (e) {
    if (searchExpanded) {
      setSearchExpanded(false);
    } else {
      e.preventDefault();
      setSearchExpanded(true);
    }
  };

  const translationsOnClick = function (e) {
    if (translationsExpanded) {
      setTranslationsExpanded(false);
    } else {
      e.preventDefault();
      setTranslationsExpanded(true);
    }
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (window && window.scrollY === 0) {
        setScrolled(false);
      } else if (window && window.scrollY > 0) {
        setScrolled(true);
      }
    };

    if (window) {
      if (window.innerWidth <= 1190) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }

      window.addEventListener('resize', function () {
        if (window.innerWidth <= 1190) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
      });

      window.addEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    returnSortedNavigation(
      props.navResultData || accropressWebsiteNavigation,
      props.navResultData ? false : true
    );
  }, [websiteNavLoading]);

  return (
    <>
      <Helmet>
        <link rel="shortcut icon" href="/favicon.ico" />
        <title>{props.gatsbyConfigSiteMetaData.title}</title>
      </Helmet>
      <header
        className={'header ' + (scrolled === true ? 'scrolled' : '')}
        id="header"
      >
        <div className="container">
          <div className="logo">
            <Link to="/">
              <img
                src={
                  props.gatsbyConfigPluginsAPOptions.siteSettings
                    .themeSettingsPayload &&
                  props.gatsbyConfigPluginsAPOptions.siteSettings
                    .themeSettingsPayload.length > 0 &&
                  props.gatsbyConfigPluginsAPOptions.siteSettings
                    .themeSettingsPayload[0] &&
                  props.gatsbyConfigPluginsAPOptions.siteSettings
                    .themeSettingsPayload[0].children
                    ? props.gatsbyConfigPluginsAPOptions.siteSettings.themeSettingsPayload[0].children.find(
                        (o) => o.id === 'logo-image'
                      ).value
                    : props.gatsbyConfigPluginsAPOptions.siteSettings.theme.themeSettingsDefinition[0].children.find(
                        (o) => o.id === 'logo-image'
                      ).default
                }
                alt="Lovelace Primary School"
              />
            </Link>
          </div>
          <div className="website-nav-wrap">
            <div className="website-nav-inner-wrap website-nav-inner-wrap-top">
              <div className="topNav">
                <ul className="topLinks">
                  <li>
                    <a href="/school-life/term-dates">
                      <span>Term Dates</span>
                    </a>
                  </li>
                  <li>
                    <a href="/school-life/newsletters">
                      <span>Latest News</span>
                    </a>
                  </li>
                  <li>
                    <a href="/weduc-login" title="Weduc Login">
                      <span>Weduc Login</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/lovelaceprimary"
                      target="_blank"
                      title="Twitter"
                    >
                      <span>Twitter</span>
                    </a>
                  </li>

                  <li
                    className={
                      searchExpanded ? 'search search-expanded' : 'search'
                    }
                  >
                    <form action="/search/" method="get">
                      <input
                        name="term"
                        id="Search"
                        placeholder="Enter your search here"
                      ></input>
                      <button
                        type="submit"
                        onClick={(e) => searchOnClick(e)}
                      ></button>
                    </form>
                  </li>

                  <li className="googleTranslations">
                    <span
                      className={
                        translationsExpanded
                          ? 'translations translations-expanded'
                          : 'translations'
                      }
                    >
                      <span
                        className="fas fa-language"
                        onClick={(e) => translationsOnClick(e)}
                      >
                        {translationsExpanded ? '' : ''}
                      </span>
                      <Translate />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {!websiteNavLoading ? (
              <div className="website-nav-inner-wrap">
                <div
                  className="menuToggler"
                  onClick={() => setToggleMobileNav(!toggleMobileNav)}
                >
                  {!toggleMobileNav ? (
                    <span className="fas fa-bars"></span>
                  ) : (
                    <span className="fas fa-times"></span>
                  )}
                </div>
                <ul
                  className={toggleMobileNav ? 'toggledMobile' : 'notToggled'}
                >
                  {websiteNavData && websiteNavData.length > 0 ? (
                    <HeaderMenuBarNested
                      websiteNavData={websiteNavData}
                      isMobile={isMobile}
                    />
                  ) : (
                    <li className="loading-nav"></li>
                  )}
                  <div
                    className="extra-contents-mobile"
                    style={{ display: toggleMobileNav ? 'block' : 'none' }}
                  >
                    <li>
                      <a
                        href="/school-life/term-dates"
                        className="parentLink"
                      >
                        <span>Term Dates</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/school-life/newsletters"
                        className="parentLink"
                      >
                        <span>Latest News</span>
                      </a>
                    </li>
                    <li>
                      <a href="/weduc-login" title="Weduc Login">
                        <span>Weduc Login</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/lovelaceprimary"
                        target="_blank"
                        title="Twitter"
                      >
                        <span>Twitter</span>
                      </a>
                    </li>
                    <li className="search search-expanded">
                      <form action="/search/" method="get">
                        <input
                          name="term"
                          id="Search"
                          placeholder="Enter your search here"
                        ></input>
                        <button type="submit" onClick={(e) => searchOnClick(e)}>
                          <span className="fas fa-search"></span>
                        </button>
                      </form>
                    </li>

                    <li className="googleTranslations">
                      <span className="translations translations-expanded">
                        <Translate />
                      </span>
                    </li>
                  </div>
                </ul>
              </div>
            ) : (
              <div className="website-nav-inner-wrap"></div>
            )}
          </div>
        </div>
        <div className="header-brush-stroke"></div>
      </header>
    </>
  );
};

export default Header;
